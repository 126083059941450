import React, { useState } from "react";
import { getSortedData } from "../../firebaseProvider";

import withFirebaseAuth from "react-with-firebase-auth";
import { firebaseAppAuth } from "../../firebaseProvider";
import { useTranslation } from "react-i18next";
import "./About.css";

import { Footer } from "../../components/Footer/Footer";
import ArticleGrid from "../../components/Article/ArticleGrid";
import { Title } from "../../components/Title/Title";

const About = ({ user }) => {
  const { t } = useTranslation();

  const [founderData, setFounderData] = useState();
  const [boardData, setBoardData] = useState();

  const loadFounderData = () => {
    getSortedData("founders").then((input) => setFounderData(input));
  };
  if (!founderData) {
    loadFounderData();
  }
  const loadBoardData = () => {
    getSortedData("board-members").then((input) => setBoardData(input));
  };
  if (!boardData) {
    loadBoardData();
  }

  return (
    <div id="about">
      <Title text={t("general.boardMembers")} />
      <div className="about-content">
        <ArticleGrid
          user={user}
          data={boardData}
          collection="board-members"
          editable={true}
          numPerRow={4}
          reloadData={loadBoardData}
          expandable={false}
        />
      </div>
      <div className="about-content-founders">
        <ArticleGrid
          user={user}
          data={founderData}
          collection="founders"
          editable={true}
          numPerRow={1}
          reloadData={loadFounderData}
          expandable={false}
        />
      </div>
      <div className="about-content-founders">
        <div className="about-adresses">
          <div className="about-revision">
            <h1>{t("about.revision")}</h1>
            <hr />
            <p>
              LIENHARD Audit AG <br /> Bleicherweg 45 <br />
              CH-8002 Zürich <br /> Tel: +41 44 201 76 00
              <a
                href="https://www.lienhard.ch"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>https://www.lienhard.ch</p>
              </a>
            </p>
          </div>
          <div className="about-revision">
            <h1>{t("about.legalAdvice")}</h1>
            <hr />
            <p>
              Reichenbach Rechtsanwälte AG <br />
              Talacker 50 <br />
              CH-8001 Zürich <br />
              Tel: +41 44 226 96 00
              <a
                href="https://www.rlaw.ch"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>https://www.rlaw.ch</p>
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withFirebaseAuth({
  firebaseAppAuth,
})(About);
