import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import withFirebaseAuth from "react-with-firebase-auth";
import { firebaseAppAuth } from "../../firebaseProvider";
import "./Immogolf.css";

import InfoRow from "../../components/InfoRow/InfoRow";
import { Footer } from "../../components/Footer/Footer";
import GallaryGolf from "../../components/GalleryGolf/GallaryGolf";
import GolfTitle from "./GolfTitle";
import EventDonators from "../../components/Donators/EventDonators";

const Immogolf = ({ user }) => {
  const { t } = useTranslation();
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 10);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <div id="immogolf">
      <div>{t()}</div>
      <GolfTitle user={user} />
      <div className="immogolf-content-intro">
        <InfoRow user={user} collection="golf-intro" numOfItems={1} />
      </div>
      <div></div>
      <div className="immogolf-content-reg-info">
        <InfoRow user={user} collection="golf-reg-info" numOfItems={1} />
      </div>
      <hr />
      <div className="immogolf-content">
        <InfoRow user={user} collection="golf-info" numOfItems={2} />
      </div>
      <hr />
      <div id="golfphotos" className="immogolf-content">
        <GallaryGolf collection="golf" />
      </div>
      <div className="immogolf-content">
        <EventDonators category="golf" />
      </div>
      <Footer />
    </div>
  );
};

export default withFirebaseAuth({
  firebaseAppAuth,
})(Immogolf);
