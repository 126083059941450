import React, { useState } from "react";
import ReactPlayer from "react-player";
import i18next from "i18next";

import { storeData } from "../../firebaseProvider";

import { SecundaryButton } from "../Button/Button";
import "./VideoPlayer.css";
import SaveInfoText from "../SaveInfoText/SaveInfoText";

const MainVideoPlayer = ({ user, data, id }) => {
  const [dataDE, setDataDE] = useState(data.urlDE);
  const [dataEN, setDataEN] = useState(data.urlEN);
  const [saveSuccess, setSaveSuccess] = useState();
  const [saveText, setSaveText] = useState();

  const saveNewURL = () => {
    if (dataDE && dataEN) {
      storeData("videos", id, {
        urlDE: dataDE,
        urlEN: dataEN,
        order: data.order,
      })
        .then(() => {
          setSaveSuccess(true);
          setSaveText("erfolgreich gespeichert");
        })
        .catch(() => {
          setSaveSuccess(false);
          setSaveText("speichern hat nicht funktioniert, probiere es nochmal");
        });
    } else {
      setSaveSuccess(false);
      setSaveText("URL darf nicht leer sein.");
    }
  };

  return dataDE && dataEN ? (
    <div className="main-video-player">
      <div className="video-player">
        <ReactPlayer
          url={i18next.language.substring(0, 2) === "de" ? dataDE : dataEN}
          width={window.innerWidth*0.9.toString()+"px"}
          height={(window.innerWidth/1.97).toString()+"px"}
          playing={true}
          volume={0.2}
          muted={true}
          controls={true}
        />
      </div>
      {user && (
        <div>
          <label>Video URL Deutsch</label>
          <input
            value={dataDE}
            onInput={(e) => {
              setDataDE(e.target.value);
              setSaveText("Achtung: Speichern nicht vergessen");
              setSaveSuccess(false);
            }}
            onChange={() => {}}
          />
          <label>Video URL Englisch</label>
          <input
            value={dataEN}
            onInput={(e) => {
              setDataEN(e.target.value);
              setSaveText("Achtung: Speichern nicht vergessen");
              setSaveSuccess(false);
            }}
            onChange={() => {}}
          />
          <SecundaryButton
            text="speichern"
            onClick={saveNewURL}
            disabled={false}
          />
          <SaveInfoText success={saveSuccess} text={saveText} />
        </div>
      )}
    </div>
  ) : (
    <div></div>
  );
};

export default MainVideoPlayer;
