import React, { useState } from "react";
import { storage } from "../../firebaseProvider";

import "./InfoRow.css";
import { FormattedText } from "../FormattedText/FormattedText";
import { PDFButton } from "../Button/Button";
import { FormattedTable } from "../FormattedTable/FormattedTable";

const InfoRowView = ({
  title,
  content,
  contentType,
  buttonText,
  onClickLink,
  collection,
}) => {
  const [linkUrl, setLinkUrl] = useState();

  if (onClickLink) {
    storage
      .child(collection)
      .child(onClickLink)
      .getDownloadURL()
      .then((url) => setLinkUrl(url));
  }

  return (
    <div className="infoRow">
      <h1>{title}</h1>
      <hr />
      {contentType === "text" && <FormattedText data={content} />}
      {contentType === "table" && <FormattedTable data={content} />}
      {linkUrl && (
        <div className="infoRow-link-button">
          <PDFButton link={linkUrl} text={buttonText} />
        </div>
      )}
    </div>
  );
};

export default InfoRowView;
